<template>
  <div class="coin-recharge">
    <van-config-provider>
      <van-nav-bar :title="pageTitle" left-arrow @click-left="goBack" :border="false"></van-nav-bar>
    </van-config-provider>
    <div class="recharge-content">
      <div class="form-wrap">
        <div class="form-title">{{$t('field.buy')}}{{$AppData.config.appCoin}}</div>
        <div class="field-wrap">
          <van-field
            v-model="amount"
            @input="inputAmount"
            class="form-field"
            type="number"
            size="large"
            :placeholder="$t('placeholder.default')"
          />
        </div>
        <template v-if="legalChannels.length > 0">
          <div class="form-title">{{$t('field.channel')}}</div>
          <div class="channel-wrap">
            <div
              v-for="channel in legalChannels"
              :key="channel.channel"
              :class="`channel-tag ${channel.channel === activeChannel ? 'active' : ''}`"
              @click="activeChannel = channel.channel"
            >{{channel.name}}</div>
          </div>
        </template>
      </div>
      <div class="page-button-wrap">
        <van-button
          size="large"
          type="primary"
          block
          @click="handleInitLegalChannelRecharge"
          :disabled="!amount"
        >{{$t('button.submit')}}</van-button>
      </div>
      <router-link class="history-btn" :to="{ name:'legalRechargeHistory' }">{{$t('title.historyData')}}</router-link>
      <div class="tip-text">{{appTip}}</div>
    </div>
    <van-popup v-model:show="showRechargeInfo" :close-on-click-overlay="false" position="bottom">
      <div class="recharge-info-title">
        {{$t('field.appraisal')}}
        <span @click="showRechargeInfo = false">{{$t('common.cancel')}}</span>
      </div>
      <div>
        <van-cell :border="false" :title="$t('field.payAmount')" :value="`${preRechargeData.payAmountStr} ${preRechargeData.payCurrency}`" />
        <van-cell :border="false" :title="$t('field.receivedAmount')" :value="`${preRechargeData.amountStr} ${preRechargeData.currency}`" />
        <van-cell :border="false" :title="$t('field.exchangeRate')" :value="`${preRechargeData.exchangeRateStr} : 1`" />
        <van-cell v-if="preRechargeData.channelName" :border="false" :title="$t('field.channel')" :value="preRechargeData.channelName" />
      </div>
      <div class="pop-button-wrap">
        <van-button
          size="large"
          type="primary"
          block
          @click="handleLegalChannelRecharge"
        >{{$t('button.confirm')}}</van-button>
      </div>
    </van-popup>
   <!-- <van-popup v-model:show="showPay" round :close-on-click-overlay="false">
      <div class="pay-dialog">
        <div v-if="jsBrige" class="pay-btn" @click="openUrl">{{$t('title.pay')}}</div>
        <a v-else class="pay-btn" :href="payUrl" target="_blank" @click="showPay = false">{{$t('title.pay')}}</a>
        <van-icon class="dialog-close-icon" name="close" color="#969799" size="28" @click="showPay = false" />
      </div>
    </van-popup> -->
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import i18n from '@/assets/lang/index';
import { queryLegalRechargeChannel, initLegalChannelRecharge, createLegalChannelRecharge } from "@/api";

export default {
  setup() {
    const router = useRouter();
    const goBack = () => router.back();
    
    const showRechargeInfo = ref(false);
    return {
      goBack, showRechargeInfo
    };
  },
  
  data() {
    return {
      pageTitle: localStorage.getItem('pageName'),
      amount: '',
      appTip: '',
      preRechargeData: {},
      legalChannels: [],
      activeChannel: null,
      // showPay: false,
      // payUrl: '',
      jsBrige: window.jsBridge,
    };
  },
  
  mounted() {
    this.fetchLegalRechargeChannel();
  },

  methods: {
    fetchLegalRechargeChannel() {
      queryLegalRechargeChannel().then(res => {
        this.legalChannels = res.data.channels || [];
        this.legalChannels.map(item => {
          if (item.isDefault) {
            this.activeChannel = item.channel;
          }
        });
      });
    },
    handleInitLegalChannelRecharge() {
      initLegalChannelRecharge({
        amount: this.amount,
        channel: this.activeChannel || undefined,
      }).then(res => {
        this.preRechargeData = res.data;
        this.showRechargeInfo = true;
      });
    },
    handleLegalChannelRecharge() {
      var newWin = window.open();
      createLegalChannelRecharge({
        token: this.preRechargeData.token,
      }).then(res => {
        if (window.jsBridge) {
          window.jsBridge.invokeNative('openUrl', res.data.payUrl, '');
        } else {
          if(newWin) {
            newWin.location = res.data.payUrl;
          } else {
            let a = document.createElement('a');
            a.setAttribute('target','_blank');
            a.setAttribute('href', res.data.payUrl);
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
        }
      });
    },
    inputAmount() {
      this.amount = this.amount.downFixed(4);
    },
    // openUrl() {
    //   if (window.jsBridge) {
    //     window.jsBridge.invokeNative('openUrl', this.payUrl, '');
    //     this.showPay = false;
    //   }
    // },
  },
};
</script>

<style lang="less">
.coin-recharge {
  padding-bottom: 80px;
  .van-tabs__line {
    background: var(--van-primary-color);
  }
  .van-tab {
    &.van-tab--active {
      color: var(--van-primary-color);
    }
    .van-tab__text {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
  .recharge-content {
    padding: 16px;
    .form-wrap {
      padding: 0 12px 16px;
      background: #fff;
      border-radius: 6px;
      overflow: hidden;
      .form-title {
        margin: 16px 0;
        font-size: 14px;
        color: #28293D;
        font-weight: 400;
      }
      .field-wrap {
        position: relative;
        .suffix-wrap {
          position: absolute;
          top: 0;
          right: 12px;
          width: 110px;
          line-height: 46px;
          text-align: right;
          font-weight: 400;
          color: #6F6F93;
          span {
            font-size: 16px;
            vertical-align: middle;
          }
          .triangle {
            width: 8px;
            margin-left: 12px; 
            vertical-align: middle;
          }
        }
      }
      .form-field {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        background: #F9FAFB;
        border-radius: 4px;
        &.with-suffix {
          padding-right: 110px;
        }
      }
      .field-extra {
        padding: 8px 0;
        font-size: 10px;
        color: #6F6F93;
        line-height: 12px;
        font-weight: 400;
        .highlight {
          color: var(--van-primary-color);
        }
      }
    }
    .channel-wrap {
      display: flex;
      .channel-tag {
        min-width: 70px;
        padding: 0 6px;
        margin-right: 10px;
        line-height: 26px;
        font-size: 14px;
        color: #6F6F93;
        background: #F9FAFB;
        border: 1px solid #F9FAFB;
        border-radius: 2px;
        text-align: center;
        box-sizing: border-box;
        &.active {
          font-weight: 700;
          color: var(--van-primary-color);
          background: #fff;
          border: 1px solid var(--van-primary-color);
        }
      }
    }
    .page-button-wrap {
      // position: fixed;
      // left: 0;
      // bottom: 0;
      // width: 100%;
      padding: 16px 0;
      // background: #fff;
      box-sizing: border-box;
    }
    .tip-text {
      padding: 16px;
      font-size: 12px;
      color: #646566;
    }
  }
  .recharge-info-title {
    margin: 0 15px;
    line-height: 60px;
    font-size: 18px;
    color: #000000;
    font-weight: 600;
    border-bottom: 1px dashed #efefef;
    span {
      display: block;
      float: right;
      font-size: 14px;
      color: #6F6F93;
      text-align: left;
    }
  }
  .pop-button-wrap {
    width: 100%;
    padding: 36px 16px 16px;
    box-sizing: border-box;
  }
  .history-btn {
    display: block;
    padding: 10px;
    font-size: 14px;
    color: #6F6F93;
    text-align: center;
  }
  .pay-dialog {
    position: relative;
    width: 300px;
    font-size: 16px;
    text-align: center;
    padding: 30px 10px;
    .pay-btn {
      display: inline-block;
      height: 20px;
      padding: 10px 30px;
      font-size: 16px;
      margin-top: 20px;
      color: #fff;
      background: var(--van-primary-color);
      border-radius: 20px;
    }
    .dialog-close-icon {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
</style>
